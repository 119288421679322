import * as React from "react"
import { Link } from 'gatsby'

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />

    <div className="flex justify-center items-center px-4 pt-12 pb-12">
      <div className='w-full max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto'>
        <div className='w-full md:w-10/12 xl:w-2/3 flex flex-col items-center mx-auto'>
          <h1 className='text-4xl md:text-7xl font-extrabold text-center'>404: Not Found</h1>
          <p className="mt-6">You just hit a route that doesn&#39;t exist... the sadness.</p>
          <Link to="/" className="underline mt-6">Go to Home Page</Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
